import React, { useRef, useCallback } from "react";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../components/affix/AutoAffix";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FormContatti from "../components/form-contatti";

import Square from "../svg/square-det.svg";

const hero = [
  {
    title: "Design thinking",
    description: "La nostra metodologia di sviluppo.",
  },
];
const breadcrumb = [{ title: "Home", link: "/" }, { title: "Design thinking" }];

const Motodologia = ({ location }) => {
  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo title={`Design thinking`} />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                Da diversi anni in Ariadne applichiamo questo approccio metodologico per lo sviluppo
                dei progetti che ci vengono affidati. In particolare, nel caso di sviluppo in
                modalità cloud-native, queste metodologie esprimono sicuramente il meglio del loro
                potenziale.
              </p>
              <p>
                La BU di Design di Ariadne adotta un approccio alla risoluzione dei problemi basato
                sul Design Thinking che ha come caratteristiche l’attenzione ai bisogni e alle
                motivazioni degli utenti finali (Human Centered Design), la collaborazione di team
                multidisciplinari e la prototipazione veloce.
              </p>
              <p>
                Ariadne adotta una metodologia di progetto che trae fondamento dai principi Agile e
                del Lean Software Development. Questa metodologia, costantemente in evoluzione, si
                traduce concretamente in specifiche buone pratiche applicate dai team nel delivery
                dei progetti.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={["lapproccio-metodologico-di-ariadne"]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#lapproccio-metodologico-di-ariadne`}
                          to={`lapproccio-metodologico-di-ariadne`}
                          className="anchor-nav__link"
                          title="L’approccio metodologico di Ariadne"
                        >
                          L’approccio metodologico di Ariadne
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div id="lapproccio-metodologico-di-ariadne" className="anchor">
                  <h2 className="anchor-text__title">L’approccio metodologico di Ariadne</h2>
                  <p>
                    <strong>APPROCCIO VALUE-DRIVEN</strong> Il progetto viene diviso in fasi
                    rilasciando le funzionalità sulla base del valore del business e del valore per
                    gli utenti massimizzando la raccolta dei feedback
                  </p>
                  <ul>
                    <li>
                      Fase 1: sviluppo del Minimum Viable Product (MVP) con le funzionalità a
                      maggior valore
                    </li>
                    <li>Fase 2: funzionalità di medio valore</li>
                    <li>Fase 3: raffinamento di dettaglio</li>
                  </ul>
                  <p>
                    <strong>RILASCIO FREQUENTE DEL SOFTWARE</strong> Ogni fase del progetto viene
                    suddivisa in Sprint di sviluppo di durata concordata (2-4 settimane) al fine di
                    rilasciare frequentemente in produzione software di valore agli utenti
                  </p>
                  <p>
                    <strong>ROADMAP CONDIVISA CON GLI STAKEHOLDER</strong> Roadmap e Backlog di
                    Progetto vengono costantemente condivisi tra gli Stakeholder al fine di
                    mantenere gli sviluppi allineati alle necessità del business e degli utenti
                    massimizzando così l’investimento del budget sugli elementi di volta in volta
                    più prioritari in base ai feedback di ogni Sprint e Fase
                  </p>
                  <p>
                    <strong>VISIONE SISTEMICA DELL’INTEGRITÀ</strong> Questo valore nei nostri team
                    si traduce in un’attenzione particolare nel mantenere l’architettura il più
                    semplice possibile, nel minimizzare il numero di righe di codice, in refactoring
                    frequente e nel trasformare i bisogni del business e degli utenti in funzioni il
                    più semplici possibili così da massimizzare la manutenibilità e consistenza del
                    prodotto
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default Motodologia;
